let awsRegion = 'us-west-2';

export default {
 api_urls: {
   reporting: 'https://j42fhsv9xg.execute-api.us-west-2.amazonaws.com/api',
   geojson: 'https://ynt6pcwxja.execute-api.us-west-2.amazonaws.com/api',
   overlays: 'https://qpykno4rrg.execute-api.us-west-2.amazonaws.com/api',
 },
 google_api_url: 'AIzaSyDI0B09O3FFEn1-usWMdtJAzat9O7SbvR4',
 main_company_term_id: 6,
 crm_base_url: 'https://crm.saas.forwoodsafety.com',
 forwood_id_url: 'https://id.saas.forwoodsafety.com?redirect_uri=https://geoeditor.saas.forwoodsafety.com',
 custom_tile_url: 'https://mapoverlays.geoeditor.saas.forwoodsafety.com',
 Auth: {
   userPoolId: 'us-west-2_3wLpx8Fn3',
   userPoolWebClientId: '38egocmm2dpr6ekj066kcpc4he',
   cookieStorage: {
     domain: '.saas.forwoodsafety.com',
     secure: true,
   },
 },
 intercom_enabled: 'False',
};
